import React from 'react'

const Footer = (props) => (
    <footer id="footer">
            <ul className="copyright">
                <li>Copyright &copy; 2020 Tae Kimura, All Rights Reserved.</li>
            </ul>
    </footer>
)

export default Footer
