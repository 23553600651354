import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/about">
            About
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#projects">
            Projects
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#contact">
            Contact
          </Link>
        </li>
      </ul>
      <ul id="icons2">
        <li>
          <a
            href="https://www.linkedin.com/in/taekimura/"
            className="icon alt fa-linkedin fa-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        {/* <li>
          <a href={resume} onclick="gtag('event', 'download', {'event_category': 'pdf','event_label': 'resume_download','value': '1'});" className="icon alt fa-file fa-lg">
            <span className="label">Resume</span>
          </a>
        </li> */}
        <li>
          <a
            href="https://github.com/taekimura"
            className="icon alt fa-github fa-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://dribbble.com/OTAE"
            className="icon alt fa-dribbble fa-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Dribbble</span>
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
