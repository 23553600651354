import React, { useState } from 'react'
import PropTypes from 'prop-types'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
      headerClass: 'logo',
    }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 300)

    const screenOffset = window.innerHeight / 1.1

    const elements = document.getElementsByClassName('fadein')
    for (let element of elements) {
      window.addEventListener('scroll', () => {
        if (window.scrollY + screenOffset > element.offsetTop) {
          element.classList.add('is-active')
        }
      })
    }
    window.onscroll = () => {
      const newScrollHeight = Math.ceil(window.scrollY / 50) * 50
      if (this.state.currentScrollHeight != newScrollHeight) {
        this.setState({ currentScrollHeight: newScrollHeight })
      }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    document.onscroll = () => {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        this.setState({ headerClass: 'scrolled' })
      } else {
        this.setState({ headerClass: 'logo' })
      }
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    })
  }

  handle() {
    this.setState({ headerClass: 'logo' })
  }

  render() {
    const opacity = Math.min(50 / this.state.currentScrollHeight, 1)
    const { children } = this.props

    return (
      <div
        className={`body ${this.state.loading} ${
          this.state.isMenuVisible ? 'is-menu-visible' : ''
        }`}
      >
        <div id="wrapper">
          <Header
            classSetting={this.state.headerClass}
            style={{ opacity }}
            onToggleMenu={this.handleToggleMenu}
          />
          {children}
          <Contact />
          <Footer />
        </div>
        <Menu onToggleMenu={this.handleToggleMenu} />
        {/* <button onclick={this.topFunction} id="myBtn" title="Go to top">Top</button> */}
      </div>
    )
  }
}

export default Layout
