import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Kimuralogo from '../assets/images/Kimuralogo.png'

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className={props.classSetting}>
      <img className="kimuralogo" src={Kimuralogo} alt="" />
    </Link>
    <nav>
      <a
        className="menu-link"
        onClick={props.onToggleMenu}
        href="javascript:;"
      ></a>
    </nav>
  </header>
)
Header.propTypes = {
  onToggleMenu: PropTypes.func,
}
Header.propTypes = {
  classSetting: PropTypes.string,
}

export default Header
