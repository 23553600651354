import React from 'react'

const Contact = props => (
  <section id="contact">
    <div className="title-container">
      <h1 className="title-container2">Contact</h1>
      Please let me know if you have any idea for linking up with me. <br />
      I’d love to hear from you.
    </div>

    <div className="inner">
      <section>
        <form
          id="fs-frm"
          name="simple-contact-form"
          accept-charset="utf-8"
          action="https://formspree.io/xqkqdkkp"
          method="POST"
        >
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6"></textarea>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send" className="special" />
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>Email</h3>
            <a href="mailto:sheepman7893@gmail.com">sheepman7893@gmail.com</a>
          </div>
        </section>
        {/* <section>
                    <div className="contact-method">
                        <span className="icon alt fa-file"></span>
                        <h3>Resume</h3>
                        <a href={resume} onclick="gtag('event', 'download', {'event_category': 'pdf','event_label': 'resume_download','value': '1'});">See my resume</a>
                    </div>
                </section> */}
        <section>
          <div className="contact-method">
            <span className="icon alt fa-linkedin"></span>
            <h3>LinkedIn</h3>
            <a
              href="https://www.linkedin.com/in/taekimura/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tae Kimura
            </a>
          </div>
        </section>
      </section>
    </div>
  </section>
)

export default Contact
